.section {
    font-family: raleway;
    color: #222;
    margin-top: 5rem;
    width: 100%;
    height: 20rem;
    z-index: 5;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}

.section-container {
    height: 20rem;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: flex-start;
}

.message {
    line-height: 1.6rem;
    color: #222;
}

.presskit {
    height: 16rem;
    min-width: 16rem;
    margin: 1rem;
}

.presskit-button {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}

.presskit-download-button {
    color: whitesmoke;
    background-color: #27283e;
    border-color: #27283e;
    display: inline-block;
    height: 38px;
    padding: 0 30px;
    text-align: center;
    font-size: 11px;
    font-weight: 600;
    line-height: 38px;
    letter-spacing: .1rem;
    border-radius: 4px;
    border: 1px solid #bbb;
    cursor: pointer;
}

@media only screen and (max-width: 1249px) {
    .section {
        font-family: raleway;
        color: #222;
        margin-top: 5rem;
        width: 100%;
        height: 50rem;
        z-index: 5;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
    }
    
    .section-container {
        height: 50rem;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
        align-items: flex-start;
    }
}