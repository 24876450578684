.newsletter {
    height: 16rem;
    min-width: 16rem;
    margin: 1rem;
}

.message {
    line-height: 1.6rem;
    color: #222;
}
.newsletter-subscribe {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: center;
}

.newsletter-input {
    height: 38px;
    width: 70%;
    background-color: #fff;
    border: 1px solid #D1D1D1;
    border-radius: 4px;
    box-shadow: none;
    box-sizing: border-box;
}

.newsletter-button {
    color: whitesmoke;
    background-color: #27283e;
    border-color: #27283e;
    display: inline-block;
    height: 38px;
    padding: 0 30px;
    text-align: center;
    font-size: 11px;
    font-weight: 600;
    line-height: 38px;
    letter-spacing: .1rem;
    border-radius: 4px;
    border: 1px solid #bbb;
    cursor: pointer;
}

.succes-message {
    text-align: center;
    line-height: 1.6rem;
    font-weight: 900;
    font-size: 1.25rem;
    color: #222;
}

@media only screen and (max-width: 560px) {
    .newsletter-input {
        height: 38px;
        width: 100%;
        background-color: #fff;
        border: 1px solid #D1D1D1;
        border-radius: 4px;
        box-shadow: none;
        box-sizing: border-box;
    }

    .newsletter-button {
        color: whitesmoke;
        background-color: #27283e;
        border-color: #27283e;
        display: inline-block;
        height: 38px;
        margin-top: 1rem;
        padding: 0 30px;
        text-align: center;
        font-size: 11px;
        font-weight: 600;
        line-height: 38px;
        letter-spacing: .1rem;
        border-radius: 4px;
        border: 1px solid #bbb;
        cursor: pointer;
    }
}