.game-section {
    width: 100%;
    height: 65rem;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}

.game-card {
    width: 80%;
    margin-top: 10rem;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items:  flex-start;
    z-index: 1;
}

.game-data-container {
    width: 25rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items:  center;
    z-index: 2;
}
 
.game-data {
    font-family: pmp;
    width: 25rem;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}

.game-logo {
    width: 24rem;
    height: 20rem;
}

.subtitle {
    font-size: 2rem;
    width: 25rem;
    text-align: center;
}

.game-background {
    position: absolute;
    width: 100%;
    height: 65rem;
    background: url('.././img/automate_banner.webp');
    background-repeat: no-repeat;
    background-size: cover; 
    background-position: center;
}

@media only screen and (max-width: 560px) {
    .game-section {
        width: 100%;
        height: 75rem;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
    }

    .game-background {
        position: absolute;
        width: 100%;
        height: 75rem;
        background: url('.././img/automate_banner_2.webp');
        background-repeat: no-repeat;
        background-size: cover; 
        background-position: center;
    }

    .game-card {
        width: 100%;
        margin-top: 5rem;
        z-index: 1;
    }
    
    .game-data-container {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items:  center;
        z-index: 2;
    }

     
    .game-data {
        font-family: pmp;
        width: 70%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
    }

    .game-logo {
        width: 17rem;
        height: 14rem;
    }

    .subtitle {
        font-size: 2rem;
        width: 100%;
        text-align: center;
    }
}