.App {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

/* NAVIGATION BAR */
.nav-bar {
  background-color: black;
  width: 100%;
  height: 4em;
}

/* CALL TO ACTION */
.call-to-action {
  background-color: whitesmoke;
  width: 94%;
  height: 60em;
  border-bottom-right-radius: 2em;
  border-bottom-left-radius: 2em;
}