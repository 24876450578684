.slider {
  height: 55rem;
  text-align: center;
  overflow: hidden;
}

.slides {
  height: 52rem;
  display: flex;
  overflow-x: auto;
  scroll-snap-type: x mandatory;
  scroll-behavior: smooth;
  -webkit-overflow-scrolling: touch;
}

.slides::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}
.slides::-webkit-scrollbar-thumb {
  background-color: #34343455;
  border-radius: 10px;
}
.slides::-webkit-scrollbar-track {
  background: transparent;
}
.slides > div {
  scroll-snap-align: start;
  flex-shrink: 0;
  min-width: 18rem;
  height: 50rem;
  margin-right: 85px;
  border-radius: 10px;
  transform-origin: center center;
  transform: scale(1);
  transition: transform 0.5s;
  position: relative;
  
  display: flex;
  justify-content: center;
  align-items: center;
}

.slider > a:active {
  top: 1px;
}

.slider > a:focus {
  background: #34343455;
}

.carousel {
  height: 50rem;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}

.carousel img {
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  border-radius: 1rem;
}
  