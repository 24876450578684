@keyframes move-stars-back {
    from {background-position: 0 0;}
    to {background-position: 1000px 0;}
}

@keyframes move-twinkle-back {
    from {background-position: 0 0;}
    to {background-position: -1000px 5000;}
}

.stars, .twinkling {
    position: absolute;
    top: 4rem; 
    width: 100%;
    height: 60rem;
    display: block;
}

.stars {
    background: black url('.././img/stars.webp') repeat top center;
    z-index: 1;
    animation: move-stars-back 300s linear infinite;
}

.twinkling {
    background: transparent url('.././img/twinkling.webp') repeat top center;
    z-index: 2;
    animation: move-twinkle-back 100s linear infinite;
}