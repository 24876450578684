.social {
    width: 100%;
    height: 3.25rem;
    padding-top: 2rem;
    padding-bottom: 2rem;
    background-color: #84C165;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.social-icon {
    width: 3rem;
    height: 3rem;
    margin-left: 1rem;
    margin-right: 1rem;
}

@media only screen and (max-width: 512px) {
    .social-icon {
        width: 3rem;
        margin: 0;
    }
}