.gameplay {
    width: 100%;
    height: 50rem;
    border-bottom-left-radius: 1rem;
    border-bottom-right-radius: 1rem;
    box-shadow: 0 10px 10px #34343455;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: flex-start;
}


@media only screen and (max-width: 560px) {

}