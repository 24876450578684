.fold {
    width: 100%;
    height: 60rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.headline {
    width: 100%;
    min-width: 15rem;;
    height: 60rem;
    padding-top: 4rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 3;
}

.header {
    height: 30rem;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    z-index: 2;
}

.logo {
    width: 10rem;
    height: 10rem;
}

.header h1 {
    font-size: 1rem;
    font-family: dosis_book;
    letter-spacing: 3px;
    color: white;
    margin-top: 1rem;
    margin-bottom: 0;
}

.headline-button-link {
    z-index: 3;
}

.headline-button {
    font-family: dosis_book;
    height: 3.5rem;
    width: 10rem;
    font-size: 1.25rem;
    color: #343434;
    background-color: whitesmoke;
    border: none;
}
